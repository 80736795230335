<template>
	<div id="main-wrapper" class="inner-pages depbonus-page vps-page">
		<div class="section banner-holder">
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>
					Free VPS Hosting
					<b-button v-b-popover.hover.click title="Conditions Apply: It is not 100% free" content="It is not 100% free" >
						* 
					</b-button>
				</h2>
			</div>
		</div>
		<div class="section bonus-trade">
			<div class="wrapper">
				<div class="title-holder t-left">
					<p>Trade from anywhere in the world conveniently without connection interruptions and save yourself from worrying about internet or power outages.  It is vital to minimize any latency while trading because a minor delay can cost you a fortune.
					<br><br>
					In line with our customer centric approach, we always want our clients to have the best trading experience possible. AAAFx brings Virtual Private Server Services, at absolutely no additional cost to you.
					</p>
				</div>
					<div class="img-holder wow fadeInLeft col">
						<img src="assets/images/vps-img1.webp" alt="AAAFx" title="AAAFx" width="419" height="369" class="fluid-img" />
					</div>
				<div class="content-holder wow fadeInRight col">
					<div class="title-holder">
						<h3>Advantages of Trading using VPS</h3>
						<ul class="listing-check">
							<li>VPS allows your trades to be executed at any time with minimum delay which results in less slippage. </li>
							<li>VPS provides endless operation of the trading platform and makes it more suitable if you use trading robots or trading signals.</li>
							<li>It allows you to trade from any place in the world even if proper internet connection is not available, the power runs out or system crashes. </li>
							<li>VPS offers anonymity, privacy and security which allows you to trade with peace of mind, knowing your essential data is safe.</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="section avail-bonus">
			<div class="wrapper">
				<div class="accordion-holder t-left">
					<h3 v-on:click="freevps ^= true" :class="[{active : freevps}]">How can I get a free VPS?</h3>
					<div class="content" v-show="freevps">
						<p>If you are a client with slightly high volumes in trading (with a deposit of at least $5,000), you are eligible for a free VPS.</p>
					</div>
					<h3 v-on:click="free ^= true"  :class="[{active : free}]">Why does AAAFX give a free VPS?</h3>
					<div class="content" v-show="free" >
						<p>Our technology partner provides us low-cost VPN hosting, in return of high volumes. We like to pass on the benefits that we earn to our clients.</p>
					</div>
					<h3 v-on:click="lesser ^= true"  :class="[{active : lesser}]">What if my deposit is lesser?</h3>
					<div class="content" v-show="lesser" >
						<p>That is no problem. You can still avail our VPS at a very low rental of only $25 per month. This rental will be deducted automatically from your trading account. </p>
					</div>
					<h3 v-on:click="othervps ^= true"  :class="[{active : othervps}]">What if I want to use some other VPS instead of the <span class="lowercase">AAAFx</span> facilitated VPS?</h3>
					<div class="content" v-show="othervps" >
						<p>If you want to use your own VPS or from a third party, we can give you a reimbursement for your VPS up to $25/month, provided your overall net deposit for the month is at least $5,000. Just share with us your VPS purchase details and monthly payment receipt in order to avail this reimbursement.</p>
					</div>
				</div>
			</div>
		</div>
		<div class="section apply-vps refer-page free-vps">
			<div class="wrapper">
				<div class="title-holder t-white wow fadeIn">
					<h3> Free VPS Configuration</h3>
				</div>
				<div class="listing">
					<div class="refer-holder col wow fadeIn">
						<div class="content-holder">
							<h3>INTEL</h3>
							<img src="assets/images/vps-ico1.webp" alt="AAAFx" title="AAAFx" width="52" height="45" class="fluid-img" />
							<p>Processor</p>
						</div>
					</div>
					<div class="refer-holder col wow fadeIn" data-wow-delay="0.3s">
						<div class="content-holder">
							<h3>1300 MB</h3>
							<img src="assets/images/vps-ico2.webp" alt="AAAFx" title="AAAFx" width="52" height="45" class="fluid-img" />
							<p>RAM</p>
						</div>
					</div>
					<div class="refer-holder col wow fadeIn" data-wow-delay="0.6s">
						<div class="content-holder">
							<h3>25 GB</h3>
							<img src="assets/images/vps-ico3.webp" alt="AAAFx" title="AAAFx" width="52" height="45" class="fluid-img" />
							<p>Bandwidth</p>
						</div>
					</div>
					<div class="refer-holder col wow fadeIn" data-wow-delay="0.9s">
						<div class="content-holder">
							<h3>2 TB</h3>
							<img src="assets/images/vps-ico4.webp" alt="AAAFx" title="AAAFx" width="52" height="45" class="fluid-img" />
							<p>Disk Space</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section apply-vps refer-page">
			<div class="wrapper">
				<div class="title-holder wow fadeIn">
					<h3> How to apply for free VPS?</h3>
				</div>
				<div class="listing">
					<div class="refer-holder col wow fadeIn">
						<div class="content-holder">
							<p>Open a Live Account</p>
						</div>
						<div class="number-holder">
							<img src="assets/images/refer1.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						</div>
					</div>
					<div class="refer-holder col wow fadeIn" data-wow-delay="0.3s">
						<div class="content-holder">
							<p>Deposit minimum $5,000 or equivalent currency</p>
						</div>
						<div class="number-holder">
							<img src="assets/images/refer2.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						</div>
					</div>
					<div class="refer-holder col wow fadeIn" data-wow-delay="0.6s">
						<div class="content-holder">
							<p>Email us at <br> <a href="mailto:support@aaafx.com">support@aaafx.com</a></p>
						</div>
						<div class="number-holder">
							<img src="assets/images/refer3.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  data() {
    return {
        freevps:false,
        free:false,
        lesser:false,
        othervps:false,
      }
    }
  }
</script>

